<template>
  <div id="box_style1_content">
    <img id="box_style1_img" :src="this.$props.item.img" />
    <div id="box_style1_text_content">
      <img id="box_icon" src="@/assets/doubleQuotes.png" />
      <div id="box_style1_text">{{ this.$props.item.des }}</div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    item: Object,
  },
})
export default class BoxWithStyle1 extends Vue {}
</script>

<style scoped>
@import url("../../less/mobile/Boxs/index.less");
</style>