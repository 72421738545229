export const kProdevPageContentInfo = {
    header: {
        title: "生 产 研 发",
        des: "如今产业高度分工时代，万非根据不同选品特质、备案资质、生产产家优势，自主把控生产研发标准，精心甄选工艺一流先进的代工厂合作生产。",
    },
    partner:{
        title: '重 要 生 产 伙 伴',
        list: [
            {
                title: "元江天美天康",
                data: [
                    "芦荟胶",
                    "芦荟水",
                    "大分子",
                    "面膜",
                    "芦荟花睡膜",
                ],
            },
            {
                title: "上海美臣化妆品有限公司",
                data: [
                    "洗发水",
                    "沐浴露",
                ],
            },
            {
                title: "广州艾禧化妆品有限公司",
                data: [
                    "洁面乳",
                    "活肤乳",
                    "面膜",
                    "防护乳",
                    "喷雾",
                ],
            },
            {
                title: "科丽思（上海）化妆品有限公司",
                data: [
                    "冻干粉",
                    "洁面泡",
                ],
            },
            {
                title: "苏州金茂日用化学品公司",
                data: [
                    "牙膏",
                ],
            },
        ],
    },
    factory: {
        title: "万 绿 工 厂",
        list: [
            {
                title: "鲜",
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/xian.png",
                des: "6小时内完成鲜叶投料加工",

            },
            {
                title: "活",
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/huo.png",
                des: "膜分离、低温真空浓缩",

            },
            {
                title: "纯",
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/chun.png",
                des: "-45℃条件下干燥而成",

            },
        ],
        img: {
            des: "云南万绿生产车间空气净化10万级。配料车间跟罐装车间采用无菌罐装运。",
            list: [{
                type: 'img_list',
                children: [
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/1.jpg",
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/2.jpg",
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/3.jpg",
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/4.jpg",
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/5.jpg",
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/6.jpg",
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/7.jpg",
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/8.jpg",
                    "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/prodev/9.jpg",
                ],
            }],
        }
    },
}